<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  Dialog, DialogPanel, TransitionChild, TransitionRoot, Disclosure, DisclosureButton, DisclosurePanel,
} from '@headlessui/vue';
import {
  Bars3Icon,
  CalendarIcon,
  HomeIcon,
  Cog6ToothIcon,
  XMarkIcon,
  BookmarkSquareIcon,
  XCircleIcon,
  BugAntIcon,
  WrenchIcon,
  BellAlertIcon,
  ChatBubbleBottomCenterTextIcon,
} from '@heroicons/vue/24/outline';
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import { useRoute } from 'vue-router';
import * as Sentry from '@sentry/vue';
import useUserStore from '../stores/user';
import { useAlerteStore } from '../stores/alertes';
import router from '../router';
import { useRentalAlertStore } from '../stores/rentalAlerts';

const route = useRoute();
const userStore = useUserStore();
const alertsStore = useAlerteStore();
alertsStore.bindAlertes();
const rentalAlertStore = useRentalAlertStore();
rentalAlertStore.bindRentalAlerts();

const nbRepairAlerts = computed(() => alertsStore.alertes.length);
const nbRentalAlerts = computed(() => rentalAlertStore.alerts.length);

const navigation = [
  { name: 'Home', label: 'Voitures', icon: HomeIcon },
  { name: 'Rentals', label: 'Locations', icon: BookmarkSquareIcon },
  { name: 'IA', label: 'Messages', icon: ChatBubbleBottomCenterTextIcon },
  { name: 'Incidents', label: 'Sinistres', icon: XCircleIcon },
  {
    name: 'Alertes', label: 'Entretiens', count: nbRepairAlerts, icon: WrenchIcon,
  },
  {
    name: 'RentalAlerts', label: 'Alertes locations', count: nbRentalAlerts, icon: BellAlertIcon,
  },
  { name: 'Planning', label: 'Planning', icon: CalendarIcon },
  {
    label: 'Paramètres',
    icon: Cog6ToothIcon,
    children: [
      { name: 'TypeEntretien', label: 'Entretiens' },
      { name: 'UsersManage', label: 'Utilisateurs' },
      { name: 'AutoPriceChanges', label: 'Grille tarifaire' },
      { name: 'Queue', label: 'File d\'attente' },
    ],
  },
];

const sidebarOpen = ref(false);

const openFeedback = () => {
  const client = Sentry.getCurrentHub().getClient<Sentry.BrowserClient>();
  const feedback = client?.getIntegration(Sentry.Feedback);

  if (feedback) {
    feedback.openDialog();
  }
};

function logout() {
  userStore.logout();
  router.push({ name: 'Login' });
}

</script>

<template>
  <TransitionRoot
    as="template"
    :show="sidebarOpen"
  >
    <Dialog
      as="div"
      class="relative z-30 lg:hidden"
      @close="sidebarOpen = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button
                  type="button"
                  class="-m-2.5 p-2.5"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Fermer la navigation</span>
                  <XMarkIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </TransitionChild>
            <!-- SidebarNav component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
              <div class="flex h-16 shrink-0 items-center space-x-2">
                <img
                  class="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Cool Cars"
                > <span class="text-white text-lg font-semibold">Cool Cars</span>
              </div>
              <nav class="flex flex-1 flex-col">
                <ul
                  role="list"
                  class="flex flex-1 flex-col justify-between"
                >
                  <li>
                    <ul
                      role="list"
                      class="-mx-2 space-y-1"
                    >
                      <li
                        v-for="item in navigation"
                        :key="item.name"
                      >
                        <router-link
                          v-if="!item.children"
                          :to="{ name: item.name }"
                          :class="[item.name === route.name ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']"
                        >
                          <component
                            :is="item.icon"
                            class="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {{ item.label }}
                          <span
                            v-if="item.count"
                            class="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-gray-600 px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-white ring-1 ring-inset ring-gray-500"
                            aria-hidden="true"
                          >{{ item.count }}</span>
                        </router-link>
                        <Disclosure
                          v-else
                          v-slot="{ open }"
                          as="div"
                        >
                          <DisclosureButton class="text-gray-400 hover:text-white hover:bg-gray-800 flex w-full gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                            <component
                              :is="item.icon"
                              class="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {{ item.label }}
                            <ChevronRightIcon
                              :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']"
                              aria-hidden="true"
                            />
                          </DisclosureButton>
                          <DisclosurePanel
                            as="ul"
                            class="mt-1 px-2"
                          >
                            <li
                              v-for="subItem in item.children"
                              :key="subItem.name"
                            >
                              <DisclosureButton class="w-full mb-0.5">
                                <router-link
                                  :class="[subItem.name === route.name ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'pl-9 flex rounded-md px-2 py-1.5 text-sm leading-6']"
                                  :to="{ name: subItem.name }"
                                >
                                  {{ subItem.label }}
                                </router-link>
                              </DisclosureButton>
                            </li>
                          </DisclosurePanel>
                        </Disclosure>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul
                      role="list"
                      class="-mx-2 space-y-1"
                    >
                      <li>
                        <button
                          type="button"
                          class="group flex w-full gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
                          @click="openFeedback"
                        >
                          <BugAntIcon
                            class="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          Signaler un bug
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-60 lg:flex-col">
    <!-- SidebarNav component, swap this element with another sidebar if you like -->
    <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
      <div class="flex h-16 shrink-0 items-center space-x-2">
        <img
          class="h-8 w-auto"
          src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
          alt="Cool Cars"
        >
        <span class="text-white text-lg font-semibold">Cool Cars</span>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul
          role="list"
          class="flex flex-1 flex-col gap-y-7"
        >
          <li>
            <ul
              role="list"
              class="-mx-2 space-y-1"
            >
              <li
                v-for="item in navigation"
                :key="item.name"
              >
                <router-link
                  v-if="!item.children"
                  :to="{ name: item.name }"
                  :class="[item.name === route.name ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']"
                >
                  <component
                    :is="item.icon"
                    class="h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                  {{ item.label }}
                  <span
                    v-if="item.count"
                    class="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-gray-600 px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-white ring-1 ring-inset ring-gray-500"
                    aria-hidden="true"
                  >{{ item.count }}</span>
                </router-link>
                <Disclosure
                  v-else
                  v-slot="{ open }"
                  as="div"
                >
                  <DisclosureButton class="text-gray-400 hover:text-white hover:bg-gray-800 flex w-full gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                    <component
                      :is="item.icon"
                      class="h-6 w-6 shrink-0"
                      aria-hidden="true"
                    />
                    {{ item.label }}
                    <ChevronRightIcon
                      :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0']"
                      aria-hidden="true"
                    />
                  </DisclosureButton>
                  <DisclosurePanel
                    as="ul"
                    class="mt-1 px-2"
                  >
                    <li
                      v-for="subItem in item.children"
                      :key="subItem.name"
                    >
                      <DisclosureButton class="w-full mb-1">
                        <router-link
                          :class="[subItem.name === route.name ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'pl-9 flex rounded-md px-2 py-1.5 text-sm leading-6']"
                          :to="{ name: subItem.name }"
                        >
                          {{ subItem.label }}
                        </router-link>
                      </DisclosureButton>
                    </li>
                  </DisclosurePanel>
                </Disclosure>
              </li>
            </ul>
          </li>
          <li class="-mx-2 mt-auto">
            <button
              type="button"
              class="group flex w-full gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
              @click="openFeedback"
            >
              <BugAntIcon
                class="h-6 w-6 shrink-0"
                aria-hidden="true"
              />
              Signaler un bug
            </button>
          </li>
          <li class="-mx-6">
            <a
              href="#"
              class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800"
              @click="logout()"
            >
              <img
                class="h-8 w-8 rounded-full bg-gray-800"
                :src="userStore.imageUrl"
                alt=""
              >
              <span class="sr-only">Votre profil</span>
              <span aria-hidden="true">Se déconnecter</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="sticky top-0 z-30 flex items-center gap-x-6 bg-gray-900 px-4 h-14 shadow-sm sm:px-6 lg:hidden">
    <button
      type="button"
      class="-m-2.5 p-2.5 text-gray-400 lg:hidden"
      @click="sidebarOpen = true"
    >
      <span class="sr-only">Ouvrir la navigation</span>
      <Bars3Icon
        class="h-6 w-6"
        aria-hidden="true"
      />
    </button>
    <div class="flex-1 text-sm font-semibold leading-6 text-white">
      {{ navigation.find(item => item.name ===
        route.name)?.label }}
    </div>
    <a
      href="#"
      @click="logout()"
    >
      <span class="sr-only">Votre profil</span>
      <img
        class="h-8 w-8 rounded-full bg-gray-800"
        :src="userStore.imageUrl"
        alt="Se déconnecter"
      >
    </a>
  </div>
</template>
