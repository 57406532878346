import {
  collection,
  getFirestore,
  onSnapshot,
  query, where,
  type Timestamp,
} from 'firebase/firestore';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { SHA256, enc } from 'crypto-js';
import useNotificationsStore from './notifications';
import fetchAuthenticated from '../lib/http/fetchAuthenticated';

export interface Car {
  id: string
  state: 'active' | 'inactive' | 'pending_approval' | 'deleted'
  plate_number: string
  currentKm: number
  currentFuel: number
  registrationDate?: Timestamp
  brand: string
  model: string
  display_address: string;
  address?: string;
  lastRepairs: Entretien[]
  notes?: string
  tracker?: string
  postalCode?: number;
  tags?: string[];
  h3: string;
}

export interface Entretien {
  id?: string
  carId: number
  repairType: string
  repairDate: Timestamp
  repairMileage: number
}

export type CarWithRepairs = {
  [key: string]: Entretien;
} & Car;

export const useCarsStore = defineStore('cars', () => {
  const db = getFirestore();

  const cars = ref<Record<string, Car>>({});
  const notifications = useNotificationsStore();
  let unsubscribeCars: (() => void) | undefined;

  const brandHues = ref<Record<string, number>>({});

  function bindCars() {
    if (unsubscribeCars) return;
    const qb = query(collection(db, 'cars'), where('state', 'in', ['active', 'inactive', 'pending_approval']));
    unsubscribeCars = onSnapshot(qb, (snapshot) => {
      snapshot.forEach((doc) => {
        const car = doc.data();
        cars.value[doc.id] = {
          ...car as Car,
          id: doc.id,
        };
      });
    });
  }

  const sortedCars = computed(() => Object.values(cars.value).sort((a, b) => {
    if (a.postalCode && b.postalCode) {
      if (a.postalCode === b.postalCode) return a.h3?.localeCompare(b.h3 || '') || 0;
      return a.postalCode < b.postalCode ? -1 : 1;
    }
    return 0;
  }));

  const carsGroupedByLocation = computed(() => {
    const grouped: { [k: string]: Car[] } = {};

    sortedCars.value.forEach((car) => {
      const code = `${car.postalCode}-${car.h3}`;
      if (!grouped[code]) {
        grouped[code] = [];
      }
      grouped[code].push(car);
    });

    return grouped;
  });

  async function saveEntretien(payload: {
    carId: string
    repairType: string
    repairDate: string
    repairMileage: number
  }) {
    const notifId = notifications.loading('Sauvegarde de l\'entretien en cours');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/cars/${payload.carId}/repairs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        repairType: payload.repairType,
        repairDate: payload.repairDate,
        repairMileage: payload.repairMileage,
      }),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'L\'entretien a été sauvegardé');
  }

  async function updateCar(id: number | string, car: { notes?: string, registrationDate?: string }) {
    const notifId = notifications.loading('Sauvegarde de la voiture en cours');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/cars/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(car),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'La voiture a été sauvegardée');
  }

  async function addTagToCar(carId: string, tag: { id?: string, name?: string, color?: string }) {
    const notifId = notifications.loading('Ajout de l\'étiquette en cours...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/cars/${carId}/tags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tag),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'L\'étiquette a été ajoutée');
  }

  async function addTagToCars(carIds: Array<string>, tag: { id?: string, name?: string, color?: string }) {
    const notifId = notifications.loading('Ajout de l\'étiquette en cours...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/cars/tags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...tag, carIds }),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'L\'étiquette a été ajoutée');
  }

  async function removeTagOnCar(carId: string, tagId: string) {
    const notifId = notifications.loading('Suppression de l\'étiquette en cours...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/cars/${carId}/tags/${tagId}`, {
      method: 'DELETE',
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'L\'étiquette a été supprimée');
  }

  async function removeTagOnCars(carIds: Array<string>, tagId: string) {
    const notifId = notifications.loading('Suppression de l\'étiquette en cours...');
    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/cars/tags/${tagId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ carIds }),
    });
    notifications.dismiss(notifId);
    notifications.handleResponse(response, 'L\'étiquette a été supprimée');
  }

  async function updatePricingForCars(payload: {
    carIds: string[] | number[],
    price: number,
    date: string
  }): Promise<boolean> {
    const notifId = notifications.loading(`Enregistrement du nouveau prix en cours pour traitement en arrière plan sur ${payload.carIds.length} voitures.`);

    const response = await fetchAuthenticated(`${import.meta.env.VITE_API_URL}/api/cars/prices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        carIds: payload.carIds.map((id) => id.toString()),
        price: payload.price,
        date: payload.date,
      }),
    });

    notifications.dismiss(notifId);

    if (response.status === 429) {
      const body = await response.json();
      notifications.error(`Limite de ${body.details.limit} tâches atteinte`, `Il y a actuellement ${body.details.count} tâches en attente de traitement. Veuillez réessayer plus tard.`, 5);
      return false;
    }
    return notifications.handleResponse(response, `Le prix a été enregistré pour traitement en arrière plan sur sur ${payload.carIds.length} voitures.`);
  }

  function getHue(str: string): number {
    if (!brandHues.value[str]) {
      const steps = 25;
      const hues = [];

      for (let i = 0; i < steps; i += 1) {
        hues.push(Math.floor((360 / steps) * i));
      }

      const hashHex = SHA256(str).toString(enc.Hex);
      const intValue = parseInt(hashHex.slice(0, 8), 16);
      let index = intValue % steps;
      let hue = hues[index];

      let i = 0;
      // Si la teinte est déjà utilisée, chercher la suivante disponible
      while (Object.values(brandHues.value).includes(hue) && i < steps) {
        index = (index + 1) % steps;
        hue = hues[index];
        i += 1;
      }

      brandHues.value[str] = hue;
    }

    return brandHues.value[str];
  }

  return {
    cars,
    sortedCars,
    carsGroupedByLocation,
    bindCars,
    saveEntretien,
    updateCar,
    addTagToCar,
    addTagToCars,
    removeTagOnCar,
    removeTagOnCars,
    updatePricingForCars,
    getHue,
  };
});
