import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import useUserStore from './stores/user';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: async () => import('./views/CarsView.vue'),
  },
  {
    path: '/alertes',
    name: 'Alertes',
    component: async () => import('./views/AlertesView.vue'),
  },
  {
    path: '/planning',
    name: 'Planning',
    component: async () => import('./views/PlanningView.vue'),
  },
  {
    path: '/rentals',
    children: [
      {
        path: '/rentals',
        name: 'Rentals',
        component: async () => import('./views/RentalsView.vue'),
      },
      {
        path: '/rentals/alerts',
        name: 'RentalAlerts',
        component: async () => import('./views/RentalAlertsView.vue'),
      },
    ],
  },
  {
    path: '/rentals/:rentalId',
    name: 'RentalPictures',
    component: async () => import('./views/RentalPicturesView.vue'),
  },
  {
    path: '/incidents',
    name: 'Incidents',
    component: async () => import('./views/IncidentsView.vue'),
  },
  {
    path: '/incidents/:incidentId',
    name: 'Incident',
    component: async () => import('./views/IncidentView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: async () => import('./views/LoginView.vue'),
    meta: { layout: 'empty' },
  },
  {
    path: '/settings/type-entretien',
    name: 'TypeEntretien',
    component: async () => import('./views/setting/TypeEntretien.vue'),
  },
  {
    path: '/settings/users-manage',
    name: 'UsersManage',
    component: async () => import('./views/setting/UsersManage.vue'),
  },
  {
    path: '/settings/queue',
    name: 'Queue',
    component: async () => import('./views/setting/TasksQueue.vue'),
  },
  {
    path: '/settings/auto-price-changes',
    name: 'AutoPriceChanges',
    component: async () => import('./views/setting/AutoPriceChanges.vue'),
  },
  {
    path: '/ai',
    name: 'IA',
    component: async () => import('./views/IAMessage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

router.beforeEach((to, _from, next) => {
  const userStore = useUserStore();
  const user = userStore.login();
  if (to.name !== 'Login' && !user) {
    next({ name: 'Login', query: { redirectUri: to.path } });
  } else {
    next();
  }
});

export default router;
